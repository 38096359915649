@property --a-radio-bg {
  syntax: "<color>";
  inherits: true;
  initial-value: var(--background_primary);
}

@property --a-radio-border-color {
  syntax: "<color>";
  inherits: true;
  initial-value: var(--border_primary);
}

@property --a-radio-border-radius {
  syntax: "<length>";
  inherits: true;
  initial-value: var(--radius_sm);
}

@property --a-radio-marker-size {
  syntax: "<length>";
  inherits: true;
  initial-value: var(--size_xs);
}

@property --a-radio-marker-icon {
  syntax: "<url>";
  inherits: true;
  initial-value: auto;
}

@property --a-radio-marker-color {
  syntax: "<custom-ident>";
  inherits: true;
  initial-value: auto;
}

.a-radio {
  --a-radio-marker-color: var(--background_inverse);
  --a-radio-bg: var(--background_primary);
  --a-radio-border-color: var(--border_primary);
  @apply relative w-fit;

  &__input {
    @apply absolute m-none p-none opacity-0 h-none w-none pointer-events-none;

    &:checked+.a-label {
      --a-radio-bg: var(--background_inverse);
      --a-radio-border-color: transparent;

      &::after {
        @apply absolute inline-flex items-center size-md;
        content: '';
        left: 4px;
        z-index: 1;
        border-radius: var(--a-radio-border-radius);
        background-color: var(--background_primary);
        height: 8px;
        width: 8px;
      }
    }

    &:focus +.a-label {
      &::before {
        outline: 2px solid light-dark(rgba(0, 0, 0, 0.2), rgba(255, 255, 255, 0.5));
        outline-offset: var(--focus-offset);
      }
    }

    &[disabled] +.a-label {
      --a-label-color: var(--text_disabled);
      &::before {
        @apply opacity-40;
      }
    }

  }

  .a-label {
    @apply relative select-none cursor-pointer;

    &::before {
      @apply inline-flex items-center border-md border-solid mr-2xs size-md z-0 shrink-0;
      content: '';
      background-color: var(--a-radio-bg);
      border-color: var(--a-radio-border-color);
      border-radius: var(--a-radio-border-radius);
    }
  }
}

.a-radio {
  --a-radio-marker-size: var(--size_md);
  --a-radio-border-radius: var(--radius_full);
}