@property --m-breadcrumbs-bg {
  syntax: "<color>";
  inherits: false;
  initial-value: transparent;
}

@property --m-breadcrumbs-divider-color {
  syntax: "<color>";
  inherits: false;
  initial-value: var(--border_subtle);
}

@property --m-breadcrumbs-height {
  syntax: "<length>";
  inherits: false;
  initial-value: auto;
}

@property --m-breadcrumbs-width {
  syntax: "<length>";
  inherits: false;
  initial-value: auto;
}

.m-breadcrumbs {
  --m-breadcrumbs-bg: transparent;
  --m-breadcrumbs-height: auto;
  --m-breadcrumbs-width: auto;

  @apply flex flex-wrap items-center gap-xs;

  background-color: var(--m-breadcrumbs-bg);
  width: var(--m-breadcrumbs-width);
  height: var(--m-breadcrumbs-height);

  &__divider {
    --m-breadcrumbs-divider-color: var(--border_subtle);
    @apply font-normal text-2xs leading-6;

    color: var(--m-breadcrumbs-divider-color);
  }
}