@property --a-codesnippet-bg {
  syntax: "<color>";
  inherits: true;
  initial-value: var(--background_subtle);
}
@property --a-codesnippet-border-color {
  syntax: "<color>";
  inherits: true;
  initial-value: var(--border_subtle);
}
@property --a-codesnippet-color {
  syntax: "<color>";
  inherits: true;
  initial-value: var(--text_tertiary);
}

.a-codesnippet {
  --a-codesnippet-bg: var(--background_subtle);
  --a-codesnippet-border-color: var(--border_subtle);
  --a-codesnippet-color: var(--text_tertiary);

  background-color: var(--a-codesnippet-bg);
  color: var(--a-codesnippet-color);
  border: 1px solid var(--a-codesnippet-border-color);
  border-radius: 6px;
  padding: 2px 8px 4px;

  @apply relative text-3xs text-center font-semibold w-fit h-fit;

  code {
    font-family: 'Noto Sans', -apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;
  }
}