/* Define custom properties for textarea styles */
@property --a-textarea-bg {
  syntax: "<color>";
  inherits: true;
  initial-value: var(--background_primary);
}

@property --a-textarea-color {
  syntax: "<color>";
  inherits: true;
  initial-value: var(--text_primary);
}

@property --a-textarea-placeholder-color {
  syntax: "<color>";
  inherits: true;
  initial-value: var(--text_tertiary);
}

@property --a-textarea-border-color {
  syntax: "<color>";
  inherits: true;
  initial-value: transparent;
}

@property --a-textarea-border-color-hover {
  syntax: "<color>";
  inherits: true;
  initial-value: transparent;
}

@property --a-textarea-text-size {
  syntax: "<length>";
  inherits: true;
  initial-value: var(--text_2xs);
}

@property --a-textarea-padding {
  syntax: "<length>";
  inherits: true;
  initial-value: auto;
}

@property --a-textarea-icon-size {
  syntax: "<length>";
  inherits: false;
  initial-value: var(--size_md);
}

@property --a-textarea-icon-y-position {
  syntax: "<length>";
  inherits: true;
  initial-value: var(--spacing_sm);
}

@property --a-textarea-icon-x-position {
  syntax: "<length>";
  inherits: true;
  initial-value: var(--spacing_sm);
}

@property --a-textarea-icon-color {
  syntax: "<color>";
  inherits: false;
  initial-value: var(--a-textarea-color);
}

.a-textarea {
  --a-textarea-icon-x-position: var(--spacing_sm);
  --a-textarea-icon-y-position: var(--a-textarea-icon-x-position);
  --a-textarea-icon-size: var(--size_md);
  --a-textarea-icon-color: var(--a-textarea-color);
  --a-textarea-bg: var(--background_primary);
  --a-textarea-color: var(--text_primary);
  --a-textarea-placeholder-color: var(--text_tertiary);
  --a-textarea-padding: var(--spacing_xs) var(--spacing_sm);
  --a-textarea-border-color: var(--border_subtle);
  --a-textarea-border-color-hover: var(--border_primary);
  --a-textarea-focus: 0px 0px 0px 4px rgba(85, 165, 211, 0.2), 0 0 0 1px rgba(85, 165, 211, 1);

  &--error {
    --a-textarea-border-color: var(--border_negative);
    --a-textarea-border-color-hover: var(--a-textarea-border-color);
    --a-textarea-focus: 0px 0px 0px 3px rgba(235, 70, 70, 0.15), 0 0 0 1px rgba(235, 70, 70, 1);
  }

  &:has(.a-textarea__input[disabled]) {
    .a-textarea__input {
      --a-textarea-bg: var(--background_disabled);
      --a-textarea-border-color: transparent;
      --a-textarea-border-color-hover: transparent;
    }
  }

  &__input {
    background-color: var(--a-textarea-bg);
    color: var(--a-textarea-color);
    padding: var(--spacing_2xs) var(--spacing_sm);
    border-color: var(--a-textarea-border-color);
    box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.12);
    z-index: 0;

    &:hover {
      border-color: var(--a-textarea-border-color-hover);
    }

    &:focus {
      box-shadow: var(--a-textarea-focus);
      outline: none;
    }

    &::placeholder {
      color: var(--a-textarea-placeholder-color);
      opacity: 1;
    }
  }

  &--autogrow {
    min-height: 32px;
    height: auto;
    field-sizing: content;
    max-height: 128px;
  }
}
