@property --a-tip-bg {
  syntax: "<color>";
  inherits: false;
  initial-value: var(--background_primary);
}

@property --a-tip-border-color {
  syntax: "<color>";
  inherits: false;
  initial-value: var(--border_subtle);
}

@property --a-tip-icon-color {
  syntax: "<color>";
  inherits: false;
  initial-value: var(--background_neutral);
}

@property --a-tip-color {
  syntax: "<color>";
  inherits: false;
  initial-value: var(--text_primary);
}


.a-tip {
  @apply flex content-stretch gap-md shrink-0 text-2xs leading-6 border-sm border-solid w-full h-full rounded-md p-md;

  --a-tip-bg: var(--background_primary);
  --a-tip-border-color: var(--border_subtle);

  background-color: var(--a-tip-bg);
  border-color: var(--a-tip-border-color);

  &__icon {

    @apply shrink-0 rounded-full w-2xs h-auto;

    background-color: var(--a-tip-icon-color);

    &--default {
      --a-tip-icon-color: var(--background_neutral);
    }

    &--warning {
      --a-tip-icon-color: var(--background_warning);
    }

  }

  &__text {

    color: var(--a-tip-color);

    &--title {
      --a-tip-color: var(--text_primary);
      @apply font-semibold;
    }

    &--description {
      --a-tip-color: var(--text_tertiary);
      @apply font-normal;
    }

  }

}