/* Define custom properties for menuitem styles */
@property --m-menuitem-bg {
  syntax: "<color>";
  inherits: false;
  initial-value: transparent;
}

@property --m-menuitem-color {
  syntax: "<color>";
  inherits: false;
  initial-value: var(--text_primary);
}

@property --m-menuitem-icon-size {
  syntax: "<length>";
  inherits: false;
  initial-value: var(--size_md);
}

@property --m-menuitem-height {
  syntax: "<length>";
  inherits: false;
  initial-value: var(--size_xl);
}

@property --m-menuitem-width {
  syntax: "<length>";
  inherits: false;
  initial-value: auto;
}

@property --m-menuitem-padding {
  syntax: "<length>";
  inherits: false;
  initial-value: var(--spacing_xs);
}

@property --m-menuitem-childrenbar-color {
  syntax: "<color>";
  inherits: false;
  initial-value: var(--border_primary);
}

@property --m-menuitem-childrenbar-width {
  syntax: "<length>";
  inherits: false;
  initial-value: var(--border_sm);
}

.m-sidebarlist {
  @apply flex flex-col gap-3xs;

  &__item {
    @apply relative flex items-center justify-start gap-xs text-2xs font-normal leading-none border-sm border-solid border-transparent rounded-sm overflow-hidden cursor-pointer shrink-0;
    --focus-offset: calc(-1 * var(--spacing_3xs));
    --m-menuitem-icon-size: var(--size_md);
    --m-menuitem-bg: transparent;
    --m-menuitem-color: var(--text_tertiary);
    --m-menuitem-height:auto;
    --m-menuitem-width: 100%;
    --m-menuitem-padding: var(--spacing_2xs) var(--spacing_xs);
    --m-menuitem-childrenbar-width: var(--border_sm);
    --m-menuitem-childrenbar-color: var(--border_subtle);
    transition: all 250ms ease-in;

    background-color: var(--m-menuitem-bg);
    color: var(--m-menuitem-color);
    height: var(--m-menuitem-height);
    width: var(--m-menuitem-width);
    padding: var(--m-menuitem-padding);
    // min-width: 201px;

    &__label {
      @apply hidden overflow-hidden text-2xs font-normal mr-auto text-left;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
    }

    &__child {
      --m-menuitem-padding: var(--spacing_2xs) var(--spacing_xs) var(--spacing_2xs) var(--spacing_xl);
    }

    &--open {
      --m-menuitem-color: var(--text_primary);
    }

    &--selected .m-sidebarlist__item {
      --m-menuitem-bg: var(--background_hover_alpha);
      --m-menuitem-color: var(--text_primary);
    }

    &:hover {
      --m-menuitem-color: var(--text_primary);
    }

    &:active {
      --m-menuitem-bg: var(--background_hover_alpha);
    }

    .a-icon,
    i-tabler,
    i-tabler svg {
      height: var(--m-menuitem-icon-size);
      width: var(--m-menuitem-icon-size);
      padding: 0;

      svg {
        height: var(--m-menuitem-icon-size);
        width: var(--m-menuitem-icon-size);
      }

      &__fav {
        color: var(--background_neutral);
        @apply opacity-0;

        &--selected {
          @apply text-yellow-500 opacity-100;
        }

        &--checked {
          @apply text-yellow-500;
        }
      }

      &__pin {
        color: var(--background_neutral);
        @apply opacity-0;

        &--selected {
          color: var(--text_tertiary);
          @apply opacity-100;
        }
      }
    }

    &:hover .a-icon__fav,
    &__child:hover .a-icon__fav,
    &__child:hover .a-icon__pin {
      @apply opacity-100;
    }

    &:active .a-icon__fav {
      @apply opacity-100 text-yellow-500;
    }
  }
}