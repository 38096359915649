@property --a-avatar-bg {
  syntax: "<color>";
  inherits: false;
  initial-value: var(--background_primary);
}

@property --a-avatar-color {
  syntax: "<color>";
  inherits: false;
  initial-value: var(--text_primary);
}

@property --a-avatar-size {
  syntax: "<lenght>";
  inherits: false;
  initial-value: var(--size_xl);
}

@property --a-avatar-radius {
  syntax: "<percentage>";
  inherits: false;
  initial-value: var(--radius_full);
}

@property --a-avatar-text-size {
  syntax: "<lenght>";
  inherits: false;
  initial-value: var(--font_3xs);
}


/* Styles for the Avatar component */
.a-avatar {
  /* Custom properties definition for the Avatar */
  --a-avatar-bg: var(--background_primary);
  --a-avatar-color: var(--text_primary);
  --a-avatar-radius: var(--radius_full);
  --a-avatar-text-size: var(--font_3xs);
  --a-avatar-size: var(--size_xl);
  --a-avatar-border-color: var(--border_subtle);

  /* Applying base styles */
  @apply relative flex justify-center items-center overflow-hidden shrink-0;

  background-color: var(--a-avatar-bg);
  color: var(--a-avatar-color);
  border: 1px solid var(--a-avatar-border-color);
  border-radius: var(--a-avatar-radius);
  height: var(--a-avatar-size);
  width: var(--a-avatar-size);

  abbr {
    text-decoration: none;
  }

  /* Styles for Avatar content */
  &__fallback {
    @apply flex items-center justify-center font-semibold;

    border-radius: var(--a-avatar-radius);
    background-color: var(--a-avatar-bg);
    color: var(--a-avatar-color);
    width: calc(var(--a-avatar-size) - 4px);
    height: calc(var(--a-avatar-size) - 4px);
    font-size: var(--a-avatar-text-size);

    /* Avatar style modifiers */
    &--neutral {
      --a-avatar-bg: var(--background_neutral);
      --a-avatar-color: var(--text_primary);
    }

    &--brand {
      --a-avatar-bg: theme('colors.brand.futureblue');
      --a-avatar-color: var(--text_primary_inverse);
    }

    &--rounded {
      border-radius: var(--a-avatar-radius);
    }

    &--squared {
      --a-avatar-radius: var(--radius_sm);
      border-radius: var(--a-avatar-radius);
    }

  }

  /* Styles for Avatar image */
  &__image {
    @apply absolute object-contain object-center;

    width: calc(var(--a-avatar-size) - 4px);
    height: calc(var(--a-avatar-size) - 4px);
    border-radius: var(--a-avatar-radius);

    &--rounded {
      border-radius: var(--a-avatar-radius);
    }

    &--squared {
      --a-avatar-radius: var(--radius_sm);
      border-radius: var(--a-avatar-radius);
    }
  }

  /* Styles for Avatar icon */
  &__icon {
    --a-avatar-bg: var(--background_neutral);
    --a-avatar-color: var(--text_primary);

    @apply absolute flex justify-center items-center;

    width: calc(var(--a-avatar-size) - 4px);
    height: calc(var(--a-avatar-size) - 4px);
    border-radius: var(--a-avatar-radius);
    background-color: var(--a-avatar-bg);
    color: var(--a-avatar-color);

    .a-icon,
    i-tabler,
    i-tabler svg {
      --a-avatar-icon-color: var(--icon_primary);
      --a-avatar-icon-size: var(--size_md);

      width: var(--a-avatar-icon-size);
      height: var(--a-avatar-icon-size);
      color: var(--a-avatar-icon-color);

      svg {
        width: var(--a-avatar-icon-size);
        height: var(--a-avatar-icon-size);
      }
    }

    &--rounded {
      border-radius: var(--a-avatar-radius);
    }

    &--squared {
      --a-avatar-radius: var(--radius_sm);
      border-radius: var(--a-avatar-radius);
    }
    
  }

  /* Avatar size modifiers */

  &--2xs {
    --a-avatar-size: 20px;
  }

  &--xs {
    --a-avatar-size: var(--size_lg);
  }

  &--base {
    --a-avatar-size: var(--size_xl);
    --a-avatar-text-size: var(--font_2xs);
  }

  &--lg {
    --a-avatar-size: var(--size_2xl);
    --a-avatar-text-size: var(--font_xs);
  }

  &--rounded {
    border-radius: var(--a-avatar-radius);
  }

  &--squared {
    --a-avatar-radius: var(--radius_sm);
    border-radius: var(--a-avatar-radius);
  }

}