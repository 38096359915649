.custom-tooltip > .p-tooltip-text {
    @apply py-2xs px-xs bg-neutral-700 font-sans text-3xs w-max max-w-lg
}

.custom-tooltip > .p-tooltip-arrow{
  border-color: transparent;
  border-top-color: #586873;
  position: absolute;
  bottom: -3px;
  border-width: 3px;
}