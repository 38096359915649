/* Define custom properties for switch styles */

@property --a-switch-bg {
  syntax: "<color>";
  inherits: true;
  initial-value: var(--background_neutral);
}

@property --a-switch-slider-bg {
  syntax: "<color>";
  inherits: true;
  initial-value: var(--background_primary);
}

@property --a-switch-radius {
  syntax: "<length>";
  inherits: true;
  initial-value: var(--radius_full);
}


.a-switch {
  --a-switch-radius: var(--radius_full);
  --a-switch-bg: var(--background_neutral);
  --a-switch-slider-bg: var(--background_primary);

  @apply relative inline-block;

  width: 32px;
  height: 18px;
  border-radius: var(--a-switch-radius);

  &:focus-within {
    box-shadow: 0 0 0 2px rgba(255, 255, 255, 1), 0px 0px 0px 4px rgba(0, 114, 188, 0.6);
    outline: none;
  }

  &__slider {
    @apply absolute cursor-pointer inset-none p-3xs ease-in-out duration-75;

    background-color: var(--a-switch-bg);
    border-radius: var(--a-switch-radius);

    &:hover {
      --a-switch-bg : var(--background_neutral_hover);
    }

    &::before {
      @apply absolute ease-in-out duration-75;
      content: "";
      height: 14px;
      width: 14px;
      background-color: var(--a-switch-slider-bg);
      border-radius: var(--a-switch-radius);
    }
  }

  input {
    @apply opacity-0 w-3xs h-3xs;
  }

  input:checked+&__slider {
    --a-switch-bg : var(--background_selected);

    &::before {
      @apply translate-x-full;
    }
  }

  input:disabled+&__slider {
    --a-switch-bg : var(--background_disabled);
    @apply cursor-default;

    &::before {
      --a-switch-slider-bg: var(--text_disabled);
    }
  }

}