@property --aside-bg {
  syntax: "<color>";
  inherits: false;
  initial-value: var(--background_primary);
}

@property --aside-border {
  syntax: "<color>";
  inherits: false;
  initial-value: var(--border_subtle);
}

.o-main {
  @apply grid grid-rows-1 items-start h-dvh max-h-dvh w-full;

  &:has(.o-asides) {
    grid-template-columns: auto 1fr;
  }
}

.o-asides {
  --aside-width: 256px;
  @apply relative flex h-full max-h-dvh w-full;

  .u-sidebar-toggle {
    --a-button-icon-size: 12px;
    @apply absolute;
    top: 50%;
    right: calc(0px - 6px);
    transform: translateY(-50%);
    z-index: 999;
    height: 48px;
    max-width: 16px;
    width: 100%;
    padding: 0;
  }

  &__wrapper {

    &--collapsed {
      --aside-width: 72px !important;
    }

  }

}

.u-collapse {
  width: var(--aside-width);
  max-width: var(--aside-width);
}

.o-aside {
  @apply grid h-full;
  --aside-bg: var(--background_primary);
  --aside-border: var(--border_subtle);
  grid-template-rows: auto 1fr auto;
  grid-template-columns: 100%;
  background-color: var(--aside-bg);
  width: var(--aside-width);
  max-width: var(--aside-width);
  border-right: 1px solid var(--aside-border);

  &__top {
    @apply flex items-start flex-col py-md px-sm;
  }

  &__content {
    @apply overflow-y-auto overflow-x-hidden h-full py-none px-sm;
  }

  &__bottom {
    @apply py-md px-sm;
  }

  &:has(.o-aside__top:empty) {
    .o-aside__top {
      @apply hidden;
    }
  }

  &:has(.o-aside__bottom:empty) {
    .o-aside__bottom {
      @apply hidden;
    }
  }
}

.o-section {
  @apply flex flex-col overflow-y-scroll overflow-x-hidden h-full w-full;
}