.m-button-group {
  @apply inline-flex h-xl rounded-lg shadow-sm shadow-neutral-100 bg-neutral-0;
}

.single-button {
  @apply border-sm border-neutral-100 rounded-md p-xs text-3xs  text-neutral-900 hover:border-neutral-200;
}

.button-left {
  @apply border-sm border-neutral-100 border-r-neutral-0 rounded-none rounded-l-md p-xs text-3xs  text-neutral-900 hover:border-neutral-200;
}

.button-center {
  @apply border-sm rounded-none border-neutral-100 border-r-neutral-0 p-xs text-3xs text-neutral-900 hover:border-neutral-200;
}

.button-right {
  @apply border-sm border-neutral-100 rounded-none rounded-r-md p-xs text-3xs text-neutral-900 hover:border-neutral-200;
}

.button-disabled {
  @apply bg-neutral-100 border-neutral-200 text-neutral-300;
  cursor: default !important;
}

.single-button,
.button-left,
.button-center,
.button-right {
  background-color: var(--background_primary);
  color: var(--text_primary);
  border-color: var(--border_subtle);

  &:not(:disabled) {

    &:hover {
      background-color: var(--background_hover_alpha);
      border-color: var(--border_primary);
    }

    &:active {
      border-color: var(--border_primary);
      background-color: var(--background_pressed_alpha);
    }

    &:focus {
      background-color: var(--background_primary);
      outline: 2px solid rgb(0, 114, 188, 0.6);
      outline-offset: var(--focus-offset);
    }
  }

  &:disabled {
    background-color: var(--background_disabled);
    color: var(--text_disabled);
    cursor: default;
  }
}