@property --a-checkbox-bg {
  syntax: "<color>";
  inherits: true;
  initial-value: var(--background_primary);
}

@property --a-checkbox-border-color {
  syntax: "<color>";
  inherits: true;
  initial-value: var(--border_subtle);
}

@property --a-checkbox-border-radius {
  syntax: "<length>";
  inherits: true;
  initial-value: var(--radius_sm);
}

@property --a-checkbox-marker-size {
  syntax: "<length>";
  inherits: true;
  initial-value: var(--size_xs);
}

@property --a-checkbox-marker-icon {
  syntax: "<url>";
  inherits: true;
  initial-value: auto;
}

@property --a-checkbox-marker-icon-color {
  syntax: "<color>";
  inherits: true;
  initial-value: var(--icon_inverse);
}

.a-checkbox {
  --a-checkbox-bg: var(--background_primary);
  --a-checkbox-border-color: var(--border_subtle);
  @apply relative w-fit;

  &__input {
    @apply absolute m-none p-none opacity-0 h-none w-none pointer-events-none;

    &:checked~.a-label {
      --a-checkbox-bg: var(--background_selected);
      --a-checkbox-border-color: var(--border_selected);

      &::after {
        @apply absolute inline-flex items-center size-md;
        content: '';
        z-index: 1;
        border-radius: var(--a-checkbox-border-radius);
        background-size: var(--a-checkbox-marker-size);
        background-position: center;
        background-repeat: no-repeat;
        mask-image: var(--a-checkbox-marker-icon);
        background-color: var(--a-checkbox-marker-icon-color);
      }

    }

    &:focus~.a-label {
      &::before {
        box-shadow: 0 1px 3px -1px rgba(0, 0, 0, 0.12), 0 0 0 2px rgba(255, 255, 255, 1), 0 0 0 4px rgba(0, 114, 188, 0.6);
      }
    }

    &[disabled]~.a-label {
      @apply cursor-default;
      --a-label-color: var(--text_disabled);
      --a-checkbox-bg: var(--background_disabled);
      --a-checkbox-border-color: var(--border_primary);

      &::before {
        background-color: var(--a-checkbox-bg);
        border-color: var(--a-checkbox-border-color);
      }

      &::after {
        --a-checkbox-marker-icon-color: var(--icon_disabled);
        background-color: var(--a-checkbox-marker-icon-color);
      }
    }

    &:not(:checked):not(:disabled):hover~.a-label::before {
      --a-checkbox-bg: var(--background_hover);
      background-color: var(--a-checkbox-bg);
    }

  }

  .a-label {
    @apply relative select-none cursor-pointer;

    &::before {
      @apply inline-flex items-center border-sm border-solid z-0 shrink-0 size-md;
      content: '';
      background-color: var(--a-checkbox-bg);
      border-color: var(--a-checkbox-border-color);
      border-radius: var(--a-checkbox-border-radius);
      box-shadow: 0 1px 3px -1px rgba(0, 0, 0, 0.12);
    }
  }

  &--inverse {

    .a-label {
      flex-direction: row-reverse;

      &::after {
        left: auto;
        right: 0;
      }

      &::before {
        @apply ml-2xs;
      }
    }
  }

  &:not(.a-checkbox--inverse) {

    .a-label {
      &::after {
        left: 0;
        right: auto;
      }

      &::before {
        @apply mr-2xs;
      }
    }
  }

}

.a-checkbox {
  --a-checkbox-marker-size: var(--size_md);
  --a-checkbox-border-radius: var(--radius_sm);
  --a-checkbox-marker-icon: url("data:image/svg+xml,%3Csvg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cg id='check'%3E%3Cpath id='Vector' d='M3.33334 7.99996L6.66668 11.3333L13.3333 4.66663' stroke='%23EFF3F5' stroke-width='1.33' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/g%3E%3C/svg%3E");
  --a-checkbox-marker-icon-color: var(--icon_inverse);
}