/* Define custom properties for menuitem styles */
@property --m-menuitem-bg {
  syntax: "<color>";
  inherits: false;
  initial-value: transparent;
}

@property --m-menuitem-color {
  syntax: "<color>";
  inherits: false;
  initial-value: var(--text_primary);
}

@property --m-menuitem-icon-size {
  syntax: "<length>";
  inherits: false;
  initial-value: var(--size_md);
}

@property --m-menuitem-height {
  syntax: "<length>";
  inherits: false;
  initial-value: var(--size_2xl);
}

@property --m-menuitem-width {
  syntax: "<length>";
  inherits: false;
  initial-value: auto;
}

@property --m-menuitem-childrenbar-color {
  syntax: "<color>";
  inherits: false;
  initial-value: var(--border_primary);
}

@property --m-menuitem-childrenbar-width {
  syntax: "<length>";
  inherits: false;
  initial-value: var(--border_sm);
}

.m-menuitem {
  @apply relative flex items-center justify-center gap-2xs text-2xs font-normal leading-none border-sm border-solid border-transparent rounded-md overflow-hidden;
  --focus-offset: calc(-1 * var(--spacing_3xs));
  --m-menuitem-icon-size: var(--size_md);
  --m-menuitem-bg: var(--background_primary);
  --m-menuitem-color: var(--text_primary);
  --m-menuitem-height: var(--size_2xl);
  --m-menuitem-width: var(--size_2xl);
  --m-menuitem-childrenbar-width: var(--border_sm);
  --m-menuitem-childrenbar-color: var(--border_subtle);
  background-color: var(--m-menuitem-bg);
  color: var(--m-menuitem-color);
  height: var(--m-menuitem-height);
  width: var(--m-menuitem-width);

  &:hover {
    --m-menuitem-bg: var(--background_hover);

    &::before {
      --show-children-bar: 0;
    }
  }

  &:active {
    --m-menuitem-bg: var(--background_pressed);
  }

  &::before {
      content: "";
      position: absolute;
      left: 16px;
      z-index: 1;
      background-color: var(--m-menuitem-childrenbar-color);
      height: 100%;
      width: var(--m-menuitem-childrenbar-width);
      opacity: var(--show-children-bar);
  }

  .a-icon,
  i-tabler,
  i-tabler svg {
    height: var(--m-menuitem-icon-size);
    width: var(--m-menuitem-icon-size);

    svg {
      height: var(--m-menuitem-icon-size);
      width: var(--m-menuitem-icon-size);
    }
  }

  &__label {
    @apply hidden whitespace-nowrap text-ellipsis overflow-hidden text-2xs;

    +.a-icon {
      @apply hidden;
    }
  }

  &--active {

    .m-menuitem {
      --m-menuitem-childrenbar-color: var(--border_primary);
      --m-menuitem-childrenbar-width: var(--border_sm);
    }

    // .m-menuitem__label {
    //   @apply font-semibold;
    // }

  }
}