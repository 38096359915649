@property --a-label-color {
  syntax: "<color>";
  inherits: false;
  initial-value: var(--text_primary);
}

@property --a-label-icon-size {
  syntax: "<length>";
  inherits: true;
  initial-value: var(--size_md);
}

@property --a-label-icon-color {
  syntax: "<color>";
  inherits: true;
  initial-value: var(--icon_subtle);
}

.a-label {
  --a-label-color: var(--text_primary);
  --a-label-icon-size: var(--size_md);
  --a-label-icon-color: var(--icon_subtle);

  @apply flex items-center gap-2xs text-2xs font-semibold mb-xs leading-6;
  color: var(--a-label-color);

  &__optional {
    --a-label-color: var(--text_tertiary);
    color: var(--a-label-color);
    @apply text-2xs leading-6 font-normal;
  }

  &--subtle {
    --a-label-color: var(--text_secondary);
    @apply font-normal;
  }

  &:has(.a-icon) .a-icon {
    height: var(--a-label-icon-size);
    width: var(--a-label-icon-size);
    color: var(--a-label-icon-color);
    @apply leading-5;

    i-tabler,
    i-tabler svg {
      height: var(--a-label-icon-size);
      width: var(--a-label-icon-size);
    }
  }

}