.upload {
  @apply w-full flex py-xl align-middle justify-center cursor-pointer h-7xl rounded-lg border-sm border-dashed;
  background-color: var(--background_hover_alpha);
  border-color: var(--border_subtle);
  transition: color ease-in 150ms, background-color ease-in 150ms, border ease-in 150ms;

  &:hover {
    background-color: var(--background_pressed_alpha);
    border-color: var(--border_primary);
  }

  &:focus,
  &:active {
    @apply border-solid;
    background-color: var(--background_pressed_alpha);
    outline: 2px solid rgb(0, 114, 188, 0.6);
    outline-offset: var(--focus-offset);
  }
}

.upload-file-text-container {
  @apply flex flex-col gap-md items-center justify-around
}

.upload-file-text{
@apply flex flex-col gap-xs items-center;
}

.upload-file-text > p{
@apply font-semibold text-xs leading-5 gap-xs;
}

.drag-and-drop-text{
@apply text-neutral-600 text-2xs;
}

.file-size{
@apply text-3xs text-neutral-400;
}

.allowed-files{
@apply text-neutral-600 text-3xs;
}

.uploaded-file-icon-container{
@apply w-2xl h-2xl bg-brand-smartnavy rounded-sm flex items-center justify-center m-xs
}

.uploaded-file-icon-container-error{
@apply w-2xl h-2xl bg-red-100 rounded-sm flex items-center justify-center m-xs
}

.uploaded-file-icon-container-done{
@apply w-2xl h-2xl bg-green-100 rounded-sm flex items-center justify-center m-xs
}

.uploaded-file-icon{
width: 16px !important;
height: 16px !important;
color: white;
}

.uploaded-file-icon > svg{
width: 16px !important;
height: 16px !important;
}

.uploaded-file-icon-error {
width: 16px !important;
height: 16px !important;
color: #eb4646 !important;
}

.uploaded-file-icon-error > svg{
width: 16px !important;
height: 16px !important;
}

.uploaded-file-icon-done {
width: 16px !important;
height: 16px !important;
color: #31ad5a !important;
}

.uploaded-file-icon-done > svg{
width: 16px !important;
height: 16px !important;
}

.icon-custom-size {
width: 16px !important;
height: 16px !important;
margin-right: 8px !important;
}

.icon-custom-size > svg {
width: 16px !important;
height: 16px !important;
}

.uploaded-file-container{
@apply flex mt-xs items-center border-sm rounded-md border-neutral-100 h-[56px] bg-neutral-0;
}

.uploaded-file-container-error{
@apply flex mt-xs items-center border-sm rounded-md border-red-500 h-[56px];
}

.uploaded-file-container-done{
@apply flex mt-xs items-center border-sm rounded-md border-green-500 h-[56px];
}

.file-uploaded{
@apply flex justify-between items-center w-full h-2xl ;
}

.file-data-container{
@apply flex flex-col;
}

.filename{
@apply text-2xs leading-6 font-semibold;
}

.file-data-status{
@apply text-3xs font-normal text-neutral-600;
}

.file-data-status-progress{
@apply text-3xs font-normal text-blue-500;
}

.file-data-status-error{
@apply text-3xs font-normal text-red-500;
}

.limit-exceded{
@apply text-3xs text-red-500 mb-xs;
}

.close-icon{
@apply hover:bg-neutral-50 rounded-md text-neutral-600 mr-xs;
display: flex !important;
align-items: center !important;
justify-content: center !important;
width: 32px !important;
height: 32px !important;
cursor: pointer;
}

.close-icon > svg{
width: 16px !important;
height: 16px !important;
}



