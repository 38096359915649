@layer primeng {
  .p-tooltip {
    position: absolute;
    display: none;
    padding: 0.25em 0.5rem;
    max-width: 12.5rem;
    pointer-events: none;
  }

  .p-tooltip-text {
    background-color: #2899e9;
    font-family: Noto Sans;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    letter-spacing: 0.04px;
    text-align: center;
    vertical-align: middle;
  }

  .p-tooltip.p-tooltip-right,
  .p-tooltip.p-tooltip-left {
    padding: 0 0.25rem;
  }

  .p-tooltip.p-tooltip-top,
  .p-tooltip.p-tooltip-bottom {
    padding: 0.25em 0;
  }

  .p-tooltip .p-tooltip-text {
    white-space: pre-line;
    word-break: break-word;
  }

  .p-tooltip-arrow {
    scale: 2;
    position: absolute;
    width: 0;
    height: 0;
    border-color: transparent;
    border-style: solid;
  }

  .p-tooltip-right .p-tooltip-arrow {
    top: 50%;
    left: 0;
    margin-top: -0.25rem;
    border-width: 0.25em 0.25em 0.25em 0;
  }

  .p-tooltip-left .p-tooltip-arrow {
    top: 50%;
    right: 0;
    margin-top: -0.25rem;
    border-width: 0.25em 0 0.25em 0.25rem;
  }

  .p-tooltip.p-tooltip-top {
    padding: 0.25em 0;
  }

  .p-tooltip-top .p-tooltip-arrow {
    bottom: 0;
    left: 50%;
    margin-left: -0.25rem;
    border-width: 0.25em 0.25em 0;
  }

  .p-tooltip-bottom .p-tooltip-arrow {
    top: 0;
    left: 50%;
    margin-left: -0.25rem;
    border-width: 0 0.25em 0.25rem;
  }
}
