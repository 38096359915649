/* Define custom properties for tooltip styles */
@property --a-tooltip-bg {
  syntax: "<color>";
  inherits: false;
  initial-value: var(--background_primary);
}

@property --a-tooltip-color {
  syntax: "<color>";
  inherits: false;
  initial-value: var(--text_primary_inverse);
}

@property --a-tooltip-border-color {
  syntax: "<color>";
  inherits: false;
  initial-value: var(--border_none);
}

.a-tooltip {
  @apply flex items-center w-fit border-sm rounded-md gap-3xs p-xs;
  --a-tooltip-bg: var(--background_inverse);
  --a-tooltip-color: var(--text_primary_inverse);
  --a-tooltip-border-color: var(--border_none);

  background-color: var(--a-tooltip-bg);
  color: var(--a-tooltip-color);
  border-color: var(--a-tooltip-border-color);
  position: relative;
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.05),
    0px 5px 10px 0px rgba(0, 0, 0, 0.05);

  &__label {
    @apply w-full whitespace-nowrap text-ellipsis overflow-hidden text-3xs font-normal text-center;
  }

  &--topstart::after {
    content: "";
    top: 100%;
    left: 15%;
    position: absolute;
    border-width: 5px;
    border-style: solid;
    border-color: var(--a-tooltip-bg) transparent transparent transparent;
  }

  &--bottomstart::after {
    content: "";
    bottom: 100%;
    left: 15%;
    position: absolute;
    border-width: 5px;
    border-style: solid;
    border-color: var(--a-tooltip-bg) transparent transparent transparent;
    transform: rotate(180deg);
  }

  &--rightstart::after {
    content: "";
    top: 20%;
    right: 100%;
    position: absolute;
    border-width: 5px;
    border-style: solid;
    border-color: var(--a-tooltip-bg) transparent transparent transparent;
    transform: rotate(90deg);
  }

  &--leftstart::after {
    content: "";
    top: 20%;
    left: 100%;
    position: absolute;
    border-width: 5px;
    border-style: solid;
    border-color: var(--a-tooltip-bg) transparent transparent transparent;
    transform: rotate(-90deg);
  }

  &--topcenter::after {
    content: "";
    top: 100%;
    left: 50%;
    margin-left: -5px;
    position: absolute;
    border-width: 5px;
    border-style: solid;
    border-color: var(--a-tooltip-bg) transparent transparent transparent;
  }

  &--bottomcenter::after {
    content: "";
    bottom: 100%;
    left: 50%;
    margin-left: -5px;
    position: absolute;
    border-width: 5px;
    border-style: solid;
    border-color: var(--a-tooltip-bg) transparent transparent transparent;
    transform: rotate(180deg);
  }

  &--rightcenter::after {
    content: "";
    top: 50%;
    right: 100%;
    margin-top: -5px;
    position: absolute;
    border-width: 5px;
    border-style: solid;
    border-color: var(--a-tooltip-bg) transparent transparent transparent;
    transform: rotate(90deg);
  }

  &--leftcenter::after {
    content: "";
    top: 50%;
    left: 100%;
    margin-top: -5px;
    position: absolute;
    border-width: 5px;
    border-style: solid;
    border-color: var(--a-tooltip-bg) transparent transparent transparent;
    transform: rotate(-90deg);
  }

  &--topend::after {
    content: "";
    top: 100%;
    right: 15%;
    position: absolute;
    border-width: 5px;
    border-style: solid;
    border-color: var(--a-tooltip-bg) transparent transparent transparent;
  }

  &--bottomend::after {
    content: "";
    bottom: 100%;
    right: 15%;
    position: absolute;
    border-width: 5px;
    border-style: solid;
    border-color: var(--a-tooltip-bg) transparent transparent transparent;
    transform: rotate(180deg);
  }

  &--rightend::after{
    content: "";
    bottom: 20%;
    right: 100%;
    position: absolute;
    border-width: 5px;
    border-style: solid;
    border-color: var(--a-tooltip-bg) transparent transparent transparent;
    transform: rotate(90deg);
  }

  &--leftend::after{
    content: "";
    bottom: 20%;
    left: 100%;
    position: absolute;
    border-width: 5px;
    border-style: solid;
    border-color: var(--a-tooltip-bg) transparent transparent transparent;
    transform: rotate(-90deg);
  }
}

// &__arrow::after {
//   content: "";
//   position: absolute;
//   border-width: 6px;
//   border-style: solid;
//   border-color: var(--a-tooltip-bg) transparent transparent transparent;

//   &--bottomleft {
//     top: 100%;
//     left: 15%;
//   }
//   &--bottomcenter {
//     top: 100%;
//     left: 50%;
//   }
//   &--bottomright {
//     top: 100%;
//     left: 85%;
//   }
// }
