.axet-animation {
  --axet-animation-width: 116px;
  --axet-animation-aspect: 116 / 166;
  @apply relative;
  width: var(--axet-animation-width);
  aspect-ratio: var(--axet-animation-aspect);
  z-index: 0;
  overflow: hidden;

  &__wrapper {
    @apply absolute inset-none inline-flex items-center justify-center;
    z-index: 1000;
    background-color: var(--border_subtle);
    mask-size: contain;
    mask-image: var(--svg-border);
    mask-repeat: no-repeat;
    mask-position: center;
    width: var(--axet-animation-width);
    aspect-ratio: var(--axet-animation-aspect);
  }

  &__bg {
    position: absolute;
    @apply absolute inline-flex items-center justify-center;
    inset: 0px;
    height: 100%;
    width: 100%;
    content: '';
    z-index: 900;
    background-color: var(--svg-bg-color-blur);
    mask-image: var(--svg-bg);
    mask-size: contain;
    mask-repeat: no-repeat;
    mask-position: center;
    mask-mode: alpha;
    backdrop-filter: blur(4px);
    width: var(--axet-animation-width);
    aspect-ratio: var(--axet-animation-aspect);
  }

  &__border {
    --axet-light-border: theme('colors.brand.futureblue');
    @apply absolute w-full h-full;
    inset: 0;
    width: var(--axet-animation-width);
    aspect-ratio: var(--axet-animation-aspect);
    z-index: 1002;
    background: conic-gradient(from 5deg at 50% 50%,
        transparent 330deg,
        var(--axet-light-border) 1turn,
        transparent 361deg);
    background-size: 100%;
    background-position: center;
    animation: axet-animation-lines 5s linear infinite;
  }

  @keyframes axet-animation-lines {
    0% {
      transform: scale(1.5) rotate(225deg);
    }

    to {
      transform: scale(1.5) rotate(1.625turn);
    }
  }

  @media (prefers-color-scheme: light) {
      --svg-bg-color-blur: rgba(0, 0, 0, 0.1);
      --svg-border: url("data:image/svg+xml,%3Csvg width='121' height='168' viewBox='0 0 121 168' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M42.8673 25.1695L42.5785 24.6701H42.0016H3.87305H2.21159L2.9894 26.1382L34.0879 84.8385L2.99512 141.851L2.18864 143.33H3.87305H42.8489H43.4344L43.7209 142.819L54.6856 123.285L77.5518 166.468L77.8336 167H78.4356H117.412H119.056L118.3 165.54L75.3523 82.5643L118.293 2.47252L119.083 1H117.412H79.2829H78.6855L78.4024 1.52603L54.6799 45.5978L42.8673 25.1695Z' stroke='black' stroke-width='2'/%3E%3C/svg%3E%0A");
      --svg-bg: url("data:image/svg+xml,%3Csvg width='121' height='168' viewBox='0 0 121 168' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M42.6512 23.6701H0.0459187L32.4484 84.8317L0 144.33H43.5156L54.1558 125.373L76.7273 168H120.195L75.9783 82.5725L120.249 0H77.5839L54.1444 43.546L42.6512 23.6701Z' fill='black'/%3E%3C/svg%3E%0A");
  }

  @media (prefers-color-scheme: dark) {
      --svg-bg-color-blur: rgba(255, 255, 255, 0.9);
      --svg-border: url("data:image/svg+xml,%3Csvg width='121' height='168' viewBox='0 0 121 168' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M42.8673 25.1695L42.5785 24.6701H42.0016H3.87305H2.21159L2.9894 26.1382L34.0879 84.8385L2.99512 141.851L2.18864 143.33H3.87305H42.8489H43.4344L43.7209 142.819L54.6856 123.285L77.5518 166.468L77.8336 167H78.4356H117.412H119.056L118.3 165.54L75.3523 82.5643L118.293 2.47252L119.083 1H117.412H79.2829H78.6855L78.4024 1.52603L54.6799 45.5978L42.8673 25.1695Z' stroke='white' stroke-width='2'/%3E%3C/svg%3E%0A");
      --svg-bg: url("data:image/svg+xml,%3Csvg width='121' height='168' viewBox='0 0 121 168' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M42.6512 23.6701H0.0459187L32.4484 84.8317L0 144.33H43.5156L54.1558 125.373L76.7273 168H120.195L75.9783 82.5725L120.249 0H77.5839L54.1444 43.546L42.6512 23.6701Z' fill='black'/%3E%3C/svg%3E%0A");
  }
}