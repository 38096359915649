.header-markdown {
  @apply flex justify-between border-sm border-neutral-100 bg-brand-white text-xs items-center rounded-t-md h-[40px] px-2xs;
}

markdown>pre {
  margin: 0 !important;
  overflow: auto;
  white-space: nowrap;
  border-top-left-radius: 0 !important;
  border-top-right-radius: 0 !important;
}

.markdown-without-toolbar markdown>pre {
  margin: 0 !important;
  overflow: auto;
  white-space: nowrap;
  border-top-left-radius: 6px !important;
  border-top-right-radius: 6px !important;
  white-space: pre;
}

.markdown-without-toolbar {
  @apply rounded-t-md truncate;
}

.copybtn {
  width: 20px !important;
  height: 20px !important;
}

.code-lang {
  @apply text-3xs font-semibold border-sm rounded-md p-2xs;
  color: var(--text_tertiary);
  border-color: var(--border_subtle)
}

.clipboard-btn {
  @apply flex items-center
}

.button-text {
  @apply ml-xs font-sans font-normal text-2xs text-neutral-600;
}