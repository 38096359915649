@property --a-badge-icon-size {
  syntax: "<length>";
  inherits: false;
  initial-value: 20px;
}

.a-icon,
i-tabler,
i-tabler svg {
  height: var(--a-badge-icon-size);
  width: var(--a-badge-icon-size);

  svg {
    height: var(--a-badge-icon-size);
    width: var(--a-badge-icon-size);
  }
}

.q-search {
  @apply surface_L1 flex flex-col gap-2xs rounded-lg p-2xs;

  &__input {
    @apply surface_L2 flex items-center px-sm py-xs gap-xs border-sm border-neutral-100 rounded-md;
  }

  &__results {
    @apply surface_L2 px-xs py-2xs border-sm border-neutral-100 rounded-md;

    max-height: 304px;
    overflow-y: auto;

    &__project {
      @apply flex items-center gap-xs px-xs py-xs text-2xs rounded-sm hover:bg-neutral-50;

      transition: all 0.3s;
    }

    &__pattern {
      @apply flex justify-center items-center w-full relative;

      height: 100px;

      &__image {
        position: absolute;
        width: 100%;
        height: 100%;
        object-fit: cover;
      }

      &__icon {
        @apply flex justify-center items-center p-sm border-sm border-neutral-100 rounded-md bg-neutral-0;

        z-index: 10;
      }
    }

    &__info {
      @apply flex flex-col justify-center items-center gap-md;
      
      width: 256px;
      text-align: center;

      &__message {
        @apply flex flex-col justify-center items-center gap-sm;

        &__title {
          font-size: 16px;
          line-height: 26px;
          font-weight: 600;
        }
      }

      &__clear {
        @apply flex justify-center items-center p-2xs border-sm border-neutral-100 rounded-md;
      }
    }
  }

  &__results--not-found {
    @apply flex flex-col justify-center items-center gap-lg surface_L2 px-sm py-xl border-sm border-neutral-100 rounded-md;
  }
}