.editor-technology {
     @apply text-3xs font-semibold border-sm rounded-md p-2xs;
     color: var(--text_tertiary);
     border-color: var(--border_subtle)
}

.monaco-editor {
     border: 1px solid transparent;
     border-bottom-left-radius: 8px;
     border-bottom-right-radius: 8px;

}

.editor-top-buttons{
     border-bottom: 1px solid #d6e0e5;

}

.editor-border {
     border: 1px solid #d6e0e5;
     border-radius: 8px;
}

.editor-shadow{
     box-shadow: 0px 1px 2px 0px #0000001F;

}

.editor-component-border {
     position: relative;
     border-right: 1px solid #d6e0e5;
     border-left: 1px solid #d6e0e5;
     border-top: 1px solid #d6e0e5;
}

.editor-icons {
     width: 20px !important;
     height: 20px !important;
}

.editor-background {
     background-color: white;
}

.monaco .monaco-scrollable-element.editor-scrollable.vs{
     left: 20px !important;
}

.monaco .line-numbers.active-line-numbe, .monaco .line-numbers{
     width: 1px !important;
}

 .monaco .monaco-scrollable-element.editor-scrollable{
     width: calc(100% - 20px) !important;

 }
