.loader {
  --loader-icon-size: var(--size_lg);
  display: inline-flex;
  align-items: center;
  justify-content: center;
  height: var(--loader-icon-size);
  width: var(--loader-icon-size);
  color: var(--text_primary);

  &--big {
    --loader-icon-size: var(--size_xl);
  }

  &__icon
  i-tabler {
    height: var(--loader-icon-size);
    width:var(--loader-icon-size);

    svg {
      height: var(--loader-icon-size);
      width: var(--loader-icon-size);
      animation: loader 1.5s ease infinite;
    }

  }

  @keyframes loader {
    0% {
      transform: rotate(0);
    }
    100% {
      transform: rotate(360deg);
    }
  }
}