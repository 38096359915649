@import url('https://fonts.googleapis.com/css2?family=Noto+Sans:ital,wdth,wght@0,62.5..100,100..900;1,62.5..100,100..900&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  @import './00_variables/variables';
  @import './01_settings/settings';
  @import './02_elements/elements';
  @import './03_objects/objects';
}

@layer components {
  @import './04_atoms/atoms';
  @import './05_molecules/molecules';
  @import './06_organisms/organisms';
  @import './07_library/library';
}

@layer utilities {
  @import './08_utilities/utilities';
}