@property --m-treeview-bg {
  syntax: "<color>";
  inherits: false;
  initial-value: transparent;
}

@property --m-treeview-color {
  syntax: "<color>";
  inherits: false;
  initial-value: var(--text_primary);
}

@property --m-treeview-icon-size {
  syntax: "<length>";
  inherits: true;
  initial-value: var(--size_md);
}

@property --m-treeview-height {
  syntax: "<length>";
  inherits: false;
  initial-value: var(--size_2xl);
}

@property --m-treeview-padding {
  syntax: "<length>";
  inherits: false;
  initial-value: auto;
}

@property --m-treeview-submenu-show {
  syntax: "<display>";
  inherits: false;
  initial-value: none;
}

.m-treeview {
  @apply flex gap-xs flex-col items-start;

  @media screen and (min-width: 400px) {
    @apply flex-row items-center;
  }

  &__item {

    &__wrapper {
      --m-treeview-height: var(--size_xl);
      --m-treeview-icon-size: var(--size_md);
      --m-treeview-padding: var(--spacing_xs);
      @apply flex gap-xs items-center rounded-md;
      background-color: var(--m-treeview-bg);
      color: var(--m-treeview-color);
      height: var(--m-treeview-height);
      padding: var(--m-treeview-padding);

      &:hover {
        --m-treeview-bg: var(--background_hover);
      }

      .a-icon,
      i-tabler,
      i-tabler svg {
        height: var(--m-treeview-icon-size);
        width: var(--m-treeview-icon-size);

        svg {
          height: var(--m-treeview-icon-size);
          width: var(--m-treeview-icon-size);
        }
      }

      .a-checkbox {
        @apply w-full;

        .a-label {
          @apply w-full mb-none text-nowrap;

          &::before {
            @apply ml-auto;
          }

          &::after {
            @apply ml-auto;
          }
        }

      }
    }
  }

  &__submenu {
    --m-treeview-submenu-show: none;
    display: var(--m-treeview-submenu-show);
    padding-left: 1.5rem;

    &--open {
      --m-treeview-submenu-show: block;
    }

    &__item {
      --m-treeview-height: var(--size_xl);
      --m-treeview-icon-size: var(--size_md);
      --m-treeview-padding: var(--spacing_xs);
      @apply flex gap-xs items-center rounded-md;
      background-color: var(--m-treeview-bg);
      color: var(--m-treeview-color);
      height: var(--m-treeview-height);
      padding: var(--m-treeview-padding);
      cursor: default;

      &:hover {
        --m-treeview-bg: var(--background_hover);
      }

      .a-icon,
      i-tabler,
      i-tabler svg {
        height: var(--m-treeview-icon-size);
        width: var(--m-treeview-icon-size);

        svg {
          height: var(--m-treeview-icon-size);
          width: var(--m-treeview-icon-size);
        }
      }
    }
  }

  &__badges {
    @apply flex gap-xs items-start;
  }

}