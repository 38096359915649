@property --m-dropdown-radius {
  syntax: "<length>";
  inherits: false;
  initial-value: var(--radius_md);
}

@property --m-dropdown-item-radius {
  syntax: "<length>";
  inherits: false;
  initial-value: var(--radius_sm);
}

@property --m-dropdown-bg {
  syntax: "<length>";
  inherits: false;
  initial-value: var(--radius_md);
}

.m-dropdown {
  --m-dropdown-radius: var(--radius_md);
  --m-dropdown-item-radius: var(--radius_sm);
  --m-dropdown-bg: var(--background_primary);
  position: relative;
  display: inline-flex;

  &:where(:focus-within)>.m-dropdown__menu,
  &:where(:hover)>.m-dropdown__menu {
    display: block;
  }

  &:where(:has(.m-dropdown__submenu)) .m-dropdown__submenu {
    position: relative;

    &:hover > .m-dropdown__menu,
    &:focus-within > .m-dropdown__menu {
        display: block;
        top: 0;
        left: 100%;
        --focus-offset: 0;
    }
  }

  &__menu {
    --show-dropdown: none;
    position: relative;
    top: 100%;
    left: 0;
    z-index: 1000;
    padding: 4px;
    display: var(--show-dropdown);
    min-width: 178px;
    width: auto;
    border: var(--border_sm) solid var(--border_subtle);
    background-color: var(--m-dropdown-bg);
    border-radius: var(--m-dropdown-radius);
    box-shadow: 0px 2px 16px -8px rgba(0, 0, 0, 0.24);

    .m-dropdown__menu {
      margin-top: -4px;
    }

    &__group {
      font-size: 10px;
      font-weight: 600;
      line-height: 16px;
      color: var(--text_disabled);
      text-transform: uppercase;
      padding: var(--spacing_2xs) var(--spacing_xs);
      cursor: default;
    }

    .a-button {
      font-weight: normal;

      &__label + .a-icon {
        margin-left: auto;
      }
    }

    > li:not(:last-of-type) {
      margin-bottom: 4px;
    }

  }

  &:where(.float-ui-container) {
    z-index: 999999;
  }

  &:not(:where(float-ui-container)) {

    float-ui-content {
      position: relative;
    }

    .float-ui-container {
      padding: 0;
      box-shadow: none;
      border: none;
      border-radius: unset;
      z-index: 9999;
    }

    .m-dropdown__menu {
      position: relative;
    }
  }



}

.float-ui-container{
  box-shadow: none !important;
  padding: 0 !important;
  border: none !important;
}
