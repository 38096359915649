@property --m-alert-icon-size {
  syntax: '<length>';
  inherits: false;
  initial-value: var(--size_lg);
}

@property --m-alert-icon-color {
  syntax: '<color>';
  inherits: false;
  initial-value: var(--icon_subtle);
}

@property --m-alert-bg {
  syntax: '<color>';
  inherits: false;
  initial-value: var(--background_primary);
}

@property --m-alert-border-radius {
  syntax: '<length>';
  inherits: false;
  initial-value: var(--radius_md);
}

@property --m-alert-padding {
  syntax: '<length>';
  inherits: true;
  initial-value: var(--spacing_md);
}

.m-alert {
  @apply flex items-start gap-xs border-sm border-solid;

  --m-alert-icon-size: var(--size_lg);
  --m-alert-bg: var(--background_primary);
  --m-alert-border-radius: var(--radius_md);
  --m-alert-padding: var(--spacing_md);

  width: 512px;
  height: auto;
  border-color: var(--border_subtle);
  border-radius: var(--m-alert-border-radius);
  padding: var(--m-alert-padding);
  background-color: var(--m-alert-bg);


  &__wrapper {
    @apply flex flex-col flex-nowrap items-start w-full h-auto gap-xs;

    &:not(:has(.m-alert__title)) {
      @apply flex items-center flex-row flex-nowrap;
    }
  }

  &__text-content {
    @apply flex flex-col flex-nowrap items-start w-full h-auto gap-2xs;
  }

  &__title {
    @apply text-xs tracking-normal font-semibold;
  }

  &__description {
    @apply text-2xs leading-6;
  }

  &__divider {
    width: 1px;
    height: 12px;
    border: 1px solid var(--border_subtle);
  }

  .a-icon,
  i-tabler,
  i-tabler svg {
    height: var(--m-alert-icon-size);
    width: var(--m-alert-icon-size);
    flex-shrink: 0;
    color: var(--m-alert-icon-color);

    svg {
      height: var(--m-alert-icon-size);
      width: var(--m-alert-icon-size);
      flex-shrink: 0;
    }

    &--close {
      --m-alert-icon-color: var(--text_tertiary);

      &:hover {
        --m-alert-icon-color: var(--text_primary);
      }
    }

    &--default {
      --m-alert-icon-color: var(--icon_subtle);
    }

    &--info {
      --m-alert-icon-color: var(--icon_info);
    }

    &--warning {
      --m-alert-icon-color: var(--icon_warning);
    }

    &--error {
      --m-alert-icon-color: var(--icon_negative);
    }

    &--success {
      --m-alert-icon-color: var(--icon_positive);
    }
  }

  p:only-child {
    margin-top: 0;
  }

}