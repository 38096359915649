.user-account {
  @apply flex items-center justify-center gap-xs p-xs;
  height: var(--size_3xl);
  width: 100%;
  border: var(--border_sm) solid var(--border_subtle);
  border-radius: var(--radius_md);
  background-color: var(--background_primary);

  &:hover,
  &:focus {
    background-color: var(--background_hover);
  }

  &__info {
    display: block;
    text-align: left;
    margin-right: auto;
  }

  &__name {
    @apply block text-3xs font-medium;
    color: var(--text_primary)
  }

  &__surname {
    @apply block text-3xs;
    color: var(--text_tertiary);
  }
  
  .user-account__dots {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    padding: var(--spacing_xs);

    i-tabler,
    i-tabler svg {
      height: var(--size_md);
      width: var(--size_md);
    }
  }
}