@import "primeng/resources/themes/lara-light-blue/theme.css";
@import "primeng/resources/primeng.css";
@import "primeicons/primeicons.css";
@import "@axet/tailwind-styles";

body {
  font-family: "Noto Sans", sans-serif;
}

.p-overlaypanel .p-overlaypanel-content {
  padding: 0 !important;
}

//Fix toast position
.o-section {
  height: 100%;
  position: relative;
}

@import "./primeng.scss";

.m-dropdown__menu button:disabled {
  display: none;
}

.m-dropdown__menu .a-icon {
  display: none;
}


