@property --a-link-color {
  syntax: "<color>";
  inherits: false;
  initial-value: var(--text_primary);
}

@property --a-link-bg {
  syntax: "<color>";
  inherits: false;
  initial-value: var(--background_primary);
}

@property --a-link-icon-size {
  syntax: "<length>";
  inherits: false;
  initial-value: var(--size_md);
}

@property --a-link-border-radius {
  syntax: "<color>";
  inherits: false;
  initial-value: var(--radius_sm);
}


.a-link {

  --a-link-border-radius: var(--radius_sm);
  --a-link-icon-size: var(--size_md);

  @apply flex items-center gap-2xs text-2xs font-semibold leading-6 w-max;

  border-radius: var(--a-link-border-radius);

  &--primary {
    --a-link-color: var(--text_primary);
    color: var(--a-link-color);

    &:hover {
      --a-link-color: var(--text_tertiary);
    }

    &:active {
      --a-link-color: var(--text_primary);
    }

    &:focus {
      --a-link-color: var(--text_primary);
      --a-link-bg: var(--background_primary);

      background: var(--a-link-bg);
      box-shadow: 0 0 0 2px rgba(255, 255, 255, 1), 0 0 0 4px rgba(0, 114, 188, 0.6);
    }

  }

  &--brand {
    --a-link-color: var(--text_link);
    color: var(--a-link-color);

    &:hover {
      --a-link-color: var(--text_link_hover);
    }

    &:active {
      --a-link-color: var(--text_link_pressed);
    }

    &:focus {
      --a-link-color: var(--text_link);
      --a-link-bg: var(--background_primary);

      background: var(--a-link-bg);
      box-shadow: 0 0 0 2px rgba(255, 255, 255, 1), 0 0 0 4px rgba(0, 114, 188, 0.6);
    }

  }

  &--disabled {
    --a-link-color: var(--text_disabled);
    color: var(--a-link-color);

    @apply pointer-events-none;
  }

  &:has(.a-icon) .a-icon {
    height: var(--a-link-icon-size);
    width: var(--a-link-icon-size);
    color: var(--a-link-color);

    @apply leading-relaxed;

    i-tabler,
    i-tabler svg {
      height: var(--a-link-icon-size);
      width: var(--a-link-icon-size);

      svg {
        height: var(--a-link-icon-size);
        width: var(--a-link-icon-size);
      }
    }
  }
}