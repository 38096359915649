// TODO: temporal review
/*
*   Si quito los importants me sobreescribe la clase del floating y es feísima, prmiera versión para testear la librería en gaia
*/
// .float-ui-container {
//     background-color: white !important;
//     padding: 0px !important;
//     z-index: 1100;
//     padding: 0 !important; 
//     border: var(--border_sm) solid var(--border_subtle) !important;
//     background-color: var(--m-dropdown-bg) !important;
//     border-radius: var(--m-dropdown-radius) !important;
//     box-shadow: 0px 2px 16px -8px rgba(0, 0, 0, 0.24) !important;
// }