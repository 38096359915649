/* Define custom properties for button styles */
@property --a-button-bg {
  syntax: "<color>";
  inherits: false;
  initial-value: transparent;
}

@property --a-button-color {
  syntax: "<color>";
  inherits: false;
  initial-value: var(--text_primary);
}

@property --a-button-border-color {
  syntax: "<color>";
  inherits: false;
  initial-value: transparent;
}

@property --a-button-text-size {
  syntax: "<length>";
  inherits: false;
  initial-value: var(--text_2xs);
}

@property --a-button-height {
  syntax: "<length>";
  inherits: false;
  initial-value: var(--size_2xl);
}

@property --a-button-width {
  syntax: "<length>";
  inherits: false;
  initial-value: auto;
}

@property --a-button-icon-size {
  syntax: "<length>";
  inherits: false;
  initial-value: var(--size_md);
}

@property --a-button-padding {
  syntax: "<length>";
  inherits: false;
  initial-value: auto;
}

/* Base button styles */
.a-button {
  @apply relative;
  background-color: var(--a-button-bg);
  color: var(--a-button-color);
  border-color: var(--a-button-border-color);
  height: var(--a-button-height);
  width: var(--a-button-width);
  padding: var(--a-button-padding);

  &:is(.a-button-icon) {
    --a-button-width: var(--a-button-height);
  }

  &:has(.a-icon-loader) {
    .a-icon {
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      animation: loaderRotation 1.5s ease infinite;
    }
    .a-button__label {
      visibility: hidden;
    }
    @keyframes loaderRotation {
      0% {
        transform: translateX(-50%) rotate(0);
      }
      100% {
        transform: translateX(-50%) rotate(360deg);
      }
    }
  }

  .a-icon,
  i-tabler,
  i-tabler svg {
    height: var(--a-button-icon-size);
    width: var(--a-button-icon-size);

    svg {
      height: var(--a-button-icon-size);
      width: var(--a-button-icon-size);
    }
  }

  &--expand {
    width: 100%;
  }

  &:disabled {
    cursor: default;
  }
}


/* Default button styles */
.a-button {
  &:not(.a-button--primary, .a-button--outline, .a-button--ghost, .a-button--text, .a-button--negative, .a-button--negative-outline, .a-button--negative-text) {
    --a-button-color: var(--text_primary);
    --a-button-border-color: var(--border_subtle);

    &:hover {
      --a-button-border-color: var(--border_primary);
    }

    &:active {
      --a-button-border-color: var(--border_primary);
      --a-button-bg: var(--background_hover);
    }
  }
}

/* Primary button style */
.a-button--primary {
  --a-button-bg: var(--background_brand);
  --a-button-color: var(--text_primary_inverse);

  &:hover {
    --a-button-bg: var(--background_brand_hover);
    --a-button-color: light-dark(var(--text_primary_inverse), var(--text_primary));
  }

  &:active {
    --a-button-bg: var(--background_brand_pressed);
    --a-button-color: light-dark(var(--text_primary_inverse), var(--text_primary));
  }

  &:focus {
    --a-button-bg: var(--background_brand_hover);
    outline: 2px solid rgb(0, 114, 188, 0.6);
    outline-offset: var(--focus-offset);
  }

  &:disabled {
    --a-button-bg: var(--background_disabled);
    --a-button-color: var(--text_disabled);
  }
}

/* Outline button style */
.a-button--outline {
  --a-button-bg: var(--background_primary);
  --a-button-color: var(--text_primary);
  --a-button-border-color: var(--border_subtle);

  &:hover {
    --a-button-bg: var(--background_hover);
    --a-button-border-color: var(--border_primary);
  }

  &:active {
    --a-button-border-color: var(--border_primary);
    --a-button-bg: var(--background_pressed);
  }

  &:focus {
    --a-button-bg: var(--background_primary);
    outline: 2px solid rgb(0, 114, 188, 0.6);
    outline-offset: var(--focus-offset);
  }

  &:disabled {
    --a-button-bg: var(--background_disabled);
    --a-button-color: var(--text_disabled);
  }
}

/* Ghost button style */
.a-button--ghost {

  &:hover {
    --a-button-bg: var(--background_hover_alpha);
    --a-button-border-color: initial;
  }

  &:active {
    --a-button-border-color: initial;
    --a-button-bg: var(--background_pressed_alpha);
  }

  &:focus {
    --a-button-bg: var(--background_hover_alpha);
    outline: 2px solid rgb(0, 114, 188, 0.6);
    outline-offset: var(--focus-offset);
  }

  &:disabled {
    --a-button-color: var(--text_disabled);
  }

}

/* Negative button style */
.a-button--negative {
  --a-button-bg: var(--background_negative);
  --a-button-color: light-dark(var(--text_primary_inverse), var(--text_primary));

  &:hover {
    --a-button-bg: var(--background_negative_hover);
  }

  &:active {
    --a-button-bg: var(--background_negative_pressed);
  }

  &:focus {
    --a-button-bg: var(--background_negative_hover);
    outline: 2px solid rgb(0, 114, 188, 0.6);
    outline-offset: var(--focus-offset);
  }

  &:disabled {
    --a-button-bg: var(--background_disabled);
    --a-button-color: var(--text_disabled);
  }

}

.a-button-icon {
  @apply justify-center;
  --a-button-icon-size: var(--size_md);
  --a-button-padding: var(--spacing_sm);

  &:has(.a-button__label) .a-button__label {
    display: none;
  }

}

.a-button__label {
  @apply text-nowrap;
}

.a-button--lg {
  --a-button-text-size: var(--font_xs);
  --a-button-height: var(--size_3xl);
  --a-button-icon-size: var(--size_md);
  --a-button-padding: var(--spacing_sm) var(--spacing_md);
}

.a-button--md {
  --a-button-text-size: var(--font_2xs);
  --a-button-height: var(--size_2xl);
  --a-button-icon-size: var(--size_md);
  --a-button-padding: var(--spacing_xs) var(--spacing_sm);
}

.a-button--sm {
  --a-button-text-size: var(--font_3xs);
  --a-button-height: var(--size_xl);
  --a-button-icon-size: var(--size_md);
  --a-button-padding: var(--spacing_2xs) var(--spacing_xs);
}

.a-button--xs {
  @apply rounded-md;
  --a-button-text-size: var(--font_3xs);
  --a-button-height: var(--size_lg);
  --a-button-icon-size: var(--size_md);
  --a-button-padding: 0 var(--spacing_xs);
}