/* Define custom properties for badge styles */
@property --a-badge-bg {
  syntax: "<color>";
  inherits: false;
  initial-value: var(--background_primary);
}

@property --a-badge-color {
  syntax: "<color>";
  inherits: false;
  initial-value: var(--text_primary);
}

@property --a-badge-border-color {
  syntax: "<color>";
  inherits: false;
  initial-value: var(--border_subtle);
}

@property --a-badge-icon-color {
  syntax: "<color>";
  inherits: false;
  initial-value: var(--icon_subtle);
}

@property --a-badge-icon-size {
  syntax: "<length>";
  inherits: false;
  initial-value: var(--size_md);
}

.a-badge {
  @apply flex items-center w-fit border-sm rounded-md gap-3xs px-2xs;
  --a-badge-icon-color: var(--icon_subtle);
  --a-badge-bg: var(--background_primary);
  --a-badge-color: var(--text_primary);
  --a-badge-border-color: var(--border_subtle);

  background-color: var(--a-badge-bg);
  color: var(--a-badge-color);
  border-color: var(--a-badge-border-color);

  &__label {
    @apply w-full whitespace-nowrap text-ellipsis overflow-hidden text-3xs font-semibold text-center;
    max-width: 10ch;
  }

  &__avatar {
    @apply py-3xs pr-xs pl-3xs rounded-full;
    --a-badge-border-color: var(--border_subtle);
    --a-badge-color: var(--text_tertiary);
    --a-badge-bg: var(--background_primary);

    &:hover{
      --a-badge-bg: var(--background_hover);
    }
  }

  &--neutral {
    --a-badge-bg: var(--background_primary);
    --a-badge-color: var(--text_primary);
    --a-badge-border-color: var(--border_subtle);
    --a-badge-icon-color: var(--icon_subtle);
  }

  &--info {
    --a-badge-bg: var(--background_info_subtle);
    --a-badge-color: var(--border_info);
    --a-badge-border-color: var(--border_info);
    --a-badge-icon-color: var(--icon_info);
  }

  &--warning {
    --a-badge-bg: var(--background_warning_subtle);
    --a-badge-color: var(--text_warning);
    --a-badge-border-color: var(--border_warning);
    --a-badge-icon-color: var(--icon_warning);
  }

  &--negative {
    --a-badge-bg: var(--background_negative_subtle);
    --a-badge-color: var(--text_negative);
    --a-badge-border-color: var(--border_negative);
    --a-badge-icon-color: var(--icon_negative);
  }

  &--positive {
    --a-badge-bg: var(--background_positive_subtle);
    --a-badge-color: var(--text_positive);
    --a-badge-border-color: var(--border_positive);
    --a-badge-icon-color: var(--icon_positive);
  }

  &--rounded{
    @apply rounded-full;
  }

  &--xs {
    height: 24px;
  }

  &--2xs {
    height: 20px;
  }

  .a-icon,
  i-tabler,
  i-tabler svg {
    --a-badge-icon-size: var(--size_md);

    @apply leading-none;
    height: var(--a-badge-icon-size);
    width: var(--a-badge-icon-size);
    color: var(--a-badge-icon-color);

    svg {
      height: var(--a-badge-icon-size);
      width: var(--a-badge-icon-size);
    }
  }

  &:focus {
    outline: 2px solid light-dark(rgba(0, 0, 0, 0.2), rgba(255, 255, 255, 0.5));
    outline-offset: var(--focus-offset);
  }

}
