@property --m-segmentedcontrol-padding {
  syntax: "<length>";
  inherits: false;
  initial-value: var(--spacing_2xs);
}

@property --m-segmentedcontrol-border-color {
  syntax: "<color>";
  inherits: false;
  initial-value: var(--border_subtle);
}

@property --m-segmentedcontrol-item-bg {
  syntax: "<color>";
  inherits: false;
  initial-value: var(--background_subtle);
}

@property --m-segmentedcontrol-item-color {
  syntax: "<color>";
  inherits: false;
  initial-value: var(--text_primary);
}

@property --m-segmentedcontrol-icon-size {
  syntax: "<length>";
  inherits: false;
  initial-value: var(--size_md);
}


.m-segmentedcontrol {
  @apply flex gap-2xs border-sm border-solid rounded-md w-full h-fit overflow-hidden shrink-0;
  --m-segmentedcontrol-padding: var(--spacing_2xs);
  --m-segmentedcontrol-border-color: var(--border_subtle);
  --m-segmentedcontrol-item-bg: var(--background_subtle);
  --m-segmentedcontrol-item-color: var(--text_primary);
  --m-segmentedcontrol-icon-size: var(--size_md);

  border-color: var(--m-segmentedcontrol-border-color);
  padding: var(--m-segmentedcontrol-padding);

  &__item {
    @apply flex items-center w-full;

    input {
      display: none;
    }

    input:checked+label {
      background-color: var(--m-segmentedcontrol-item-bg);
      color: var(--m-segmentedcontrol-item-color);
    }

    label {
      @apply flex items-center justify-center gap-2xs text-2xs text-center font-semibold leading-6 cursor-pointer rounded-sm w-full;
      padding: var(--spacing_2xs) var(--spacing_xs);

      .a-icon,
      i-tabler,
      i-tabler svg {
        height: var(--m-segmentedcontrol-icon-size);
        width: var(--m-segmentedcontrol-icon-size);

        svg {
          height: var(--m-segmentedcontrol-icon-size);
          width: var(--m-segmentedcontrol-icon-size);
        }
      }
    }

    label .a-icon {
      @apply leading-none;
    }
  }
}