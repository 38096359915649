/* Define custom properties for input styles */
@property --a-input-bg {
  syntax: "<color>";
  inherits: true;
  initial-value: var(--background_primary);
}

@property --a-input-color {
  syntax: "<color>";
  inherits: true;
  initial-value: var(--text_primary);
}

@property --a-input-placeholder-color {
  syntax: "<color>";
  inherits: true;
  initial-value: var(--text_tertiary);
}

@property --a-input-border-color {
  syntax: "<color>";
  inherits: true;
  initial-value: transparent;
}

@property --a-input-border-color-hover {
  syntax: "<color>";
  inherits: true;
  initial-value: transparent;
}

@property --a-input-text-size {
  syntax: "<length>";
  inherits: true;
  initial-value: var(--text_2xs);
}

@property --a-input-height {
  syntax: "<length>";
  inherits: true;
  initial-value: var(--size_2xl);
}

@property --a-input-padding {
  syntax: "<length>";
  inherits: true;
  initial-value: auto;
}

@property --a-input-radius {
  syntax: "<length>";
  inherits: true;
  initial-value: var(--radius_md);
}

@property --a-input-icon-size {
  syntax: "<length>";
  inherits: false;
  initial-value: var(--size_md);
}

@property --a-input-icon-y-position {
  syntax: "<length>";
  inherits: true;
  initial-value: var(--spacing_sm);
}

@property --a-input-icon-x-position {
  syntax: "<length>";
  inherits: true;
  initial-value: var(--spacing_sm);
}

@property --a-input-icon-color {
  syntax: "<color>";
  inherits: false;
  initial-value: var(--a-input-color);
}

@property --a-label-color {
  syntax: "<color>";
  inherits: false;
  initial-value: var(--text_primary);
}

@property --a-helper-color {
  syntax: "<color>";
  inherits: false;
  initial-value: var(--text_primary);
}

.a-input {
  --a-input-icon-x-position: var(--spacing_md);
  --a-input-icon-y-position: var(--a-input-icon-x-position);
  --a-input-icon-size: var(--size_md);
  --a-input-icon-color: var(--a-input-color);
  --a-input-bg: var(--background_primary);
  --a-input-color: var(--text_primary);
  --a-input-placeholder-color: var(--text_tertiary);
  --a-input-height: var(--size_xl);
  --a-input-padding: 0 var(--spacing_sm);
  --a-input-border-color: var(--border_subtle);
  --a-input-border-color-hover: var(--border_primary);
  --a-label-color: var(--text_primary);
  --a-helper-color: var(--text_primary);
  --a-input-focus: 0px 0px 0px 4px rgba(85, 165, 211, 0.2), 0 0 0 1px rgba(85, 165, 211, 1);
  --a-input-radius: var(--radius_md);

  &--error {
    --a-input-color: var(--text_primary);
    --a-input-border-color: var(--border_negative);
    --a-input-border-color-hover: var(--a-input-border-color);
    --a-helper-color: var(--text_negative);
    --a-input-focus: 0px 0px 0px 3px rgba(235, 70, 70, 0.15), 0 0 0 1px rgba(235, 70, 70, 1);
  }

  &--large {
    --a-input-icon-x-position: var(--spacing_sm);
    --a-input-icon-y-position: var(--a-input-icon-x-position);
    --a-input-height: var(--size_2xl);
    --a-input-padding: 0 var(--spacing_xs);
  }

  &--rounded {
    --a-input-radius: var(--radius_full);
  }

  &:has(.a-input__input[disabled]) {
    .a-input__input {
      --a-input-bg: var(--background_disabled);
      --a-input-border-color: var(--border_subtle);
      --a-input-border-color-hover: var(--border_subtle);
    }
  }

  &__wrapper {
    @apply relative;

    &:has(.a-input__toggle) {
      .a-input__toggle {
        @apply absolute;
        right: 0;
        bottom: 0;
        border-top-right-radius: var(--radius_md);
        border-bottom-right-radius: var(--radius_md);
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
        border-left-width: 1px;
        border-left-style: solid;
        border-left-color: var(--border_subtle);
        width: var(--a-input-height);
        height: var(--a-input-height);
      }
    }

    &:has(.a-icon) .a-icon {
      height: var(--a-input-icon-size);
      width: var(--a-input-icon-size);

      i-tabler,
      i-tabler svg {
        height: var(--a-input-icon-size);
        width: var(--a-input-icon-size);

        svg {
          height: var(--a-input-icon-size);
          width: var(--a-input-icon-size);
          ;
        }
      }
    }

    &:has(.a-input__lefticon) {
      .a-input__lefticon {
        @apply absolute;
        left: var(--spacing_sm);
        top: 50%;
        transform: translateY(-50%);

        i-tabler svg {
          margin-top: -2px;
          color: var(--a-input-icon-color);
        }
      }

      .a-input__input {
        padding-left: var(--spacing_xl);
      }
    }

    &:has(.a-input__righticon) {
      .a-input__righticon {
        @apply absolute;
        right: var(--spacing_sm);
        top: 50%;
        transform: translateY(-50%);

        i-tabler svg {
          margin-top: -2px;
          color: var(--a-input-icon-color);
        }
      }

      .a-input__input {
        padding-right: var(--spacing_xl);
      }
    }
  }

  &__input {
    background-color: var(--a-input-bg);
    color: var(--a-input-color);
    height: var(--a-input-height);
    padding: var(--a-input-padding);
    border-color: var(--a-input-border-color);
    box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.12);
    z-index: 0;

    &:hover {
      border-color: var(--a-input-border-color-hover);
    }

    &:focus {
      box-shadow: var(--a-input-focus);
      outline: none;
    }

    &::placeholder {
      color: var(--a-input-placeholder-color);
      opacity: 1;
    }
  }

  .a-label,
  .a-helper {
    @apply flex items-center gap-2xs text-2xs font-semibold mb-xs;
    color: var(--a-label-color);
  }

  input[type="search"]::-webkit-search-cancel-button {
    -webkit-appearance: none;
    margin: 0;
    width: var(--a-input-icon-size);
    height: var(--a-input-icon-size);
    background-image: url('data:image/svg+xml,%3Csvg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"%3E%3Cpath d="M12 4L4 12M4 4L12 12" stroke="%236D7C86" stroke-width="1.33" stroke-linecap="round" stroke-linejoin="round"/%3E%3C/svg%3E');
  }
}